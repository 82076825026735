
.correct {
    background-color: #c3e6cb;
  }
  
  .incorrect {
    background-color: #f8d7da;
  }
  .custom-card {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: opacity 2s ease;
  }
  
  .card-title {
    font-weight: bold;
  }
  
  .text-center {
    text-align: center;
  }
  
  .correct {
    background-color: #c3e6cb;
  }
  
  .incorrect {
    background-color: #f8d7da;
  }
  .custom-card:hover {
    transform: translateY(-5px);
  }
  .shade {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  @import 'typeface-inconsolata';

.cart-title {
  font-family: 'Inconsolata', monospace;
  /* Add other styling properties for your modal content */
}

  