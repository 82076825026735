.home_modal {
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);

  }
  .modal-content{
    position: relative;
    top: 100px; /* Adjust the value to move the modal lower */
  }
  
  .custom-card {
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .custom-card:hover {
    transform: translateY(-5px);
  }
  
  .card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  
  .card-text {
    font-size: 16px;
    color: #555;
  }
  .home_modal.animating {
    animation: shrinkOut 0.5s ease;
  }
  
  .overlay.animating {
    animation: popIn 0.5s ease;
  }
  
  @keyframes shrinkOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0);
      opacity: 0;
    }
  }
  
  @keyframes popIn {
    0% {
      transform: scale(0);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  


  