.fade-in {


    transition: opacity 1s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}
@import 'typeface-orbitron';

.modal-header {
  font-family: 'Orbitron', sans-serif;
}
@import 'typeface-inconsolata';

.modal-content {
  font-family: 'Inconsolata', monospace;
  /* Add other styling properties for your modal content */
}

