.dashboard-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .dashboard-heading {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .quiz-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .quiz-item {
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.3s ease;

  }
  .quiz-item:hover{
    transform: translateY(-5px);
  }
  
  .quiz-title {
    margin-top: 0;
    margin-bottom: 10px;
  }
  
  .quiz-description {
    margin-bottom: 10px;
  }
  
  .quiz-details {
    display: flex;
    justify-content: center;
  }
  
  .quiz-score,
  .quiz-date {
    margin: 0;
  }
  
  @media screen and (max-width: 480px) {
    .quiz-list {
      grid-template-columns: 1fr;
    }
  }
  .score-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .score {
    margin-right: 10px;
    font-size: 16px;
    font-weight: bold;
  }
  
  .score-bar {
    width: 200px;
    height: 10px;
    background-color: lightgray;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .score-fill {
    height: 100%;
    background-color: #4caf50;
    transition: width 0.3s ease;
  }
  .quiz-history-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .quiz-history-progress-bar {
    width: 10px;
    background-color: #e0e0e0;
    margin-right: 10px;
  }
  
  .quiz-history-progress-bar::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: #4caf50;
    transition: height 0.3s ease-in-out;
  }
  
  .quiz-history-item:hover .quiz-history-progress-bar::after {
    height: 0;
  }
  
  .quiz-history-score {
    font-size: 14px;
    color: #000;
    margin-right: 5px;
  }
  
  .perfect {
    background-color: #4caf50; /* Green */
  }
  
  .imperfect {
    background-color: #ff0000; /* Red */
  }
  
  .quiz-history-date {
    font-size: 14px;
    color: #888;
  }
  .box-container{
    display:flex;
    justify-content:center;
    gap: 10px;

  }
  .centered-div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top:100px;
    max-height: 100vh;
    width:100%;
    


  }
  .startButton{
    width:100%;
    height:auto;
  }
  .startButtonImg{
    width:160px;
    height:auto;
  }



  

  
  