.googleLogin{
    width:80%;
    height:auto;
}
.modal-content{
    width:250px;
    height:auto;
}
ul.nav-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  ul.nav-tabs li {
    flex: 1;
    text-align: center;
  }

  ul.nav-tabs li button {
    color: black; /* Set the color for the button text */
    background-color: transparent; /* Remove the background color */
    border: none; /* Remove the border */
    cursor: pointer;
    outline: none; /* Remove the outline when the button is clicked */
  }
  body > div.fade.user-modal.text-center.modal.show > div > div > div.modal-header > ul > li:nth-child(2) > button {
    color:black

  }
  body > div.fade.user-modal.text-center.modal.show > div > div > div.modal-header > ul > li:nth-child(3) > button {
    color:black

  }
  body > div.fade.user-modal.text-center.modal.show > div > div > div.modal-header > ul > li:nth-child(1) > button {
    color:black

  }
  ul.nav-tabs li button.active {
    font-weight: bold; /* Optional: Add a style for active buttons */
  }
  
