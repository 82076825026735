.show-results-modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .show-results-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .question-text {
    font-size: 18px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .answer {
    padding: 10px;
    margin-bottom: 5px;
    background-color: #f1f1f1;
    border-radius: 5px;
  }
  
  .correct-answer {
    background-color: #baffb3;
    font-weight: bold;
  }
  
  .incorrect-answer {
    background-color: #ffb3b3;
    font-weight: bold;
  }

  .question-item.light-green {
    background-color: lightgreen;
  }
  
  .question-item.light-red {
    background-color: lightcoral;
  }
  