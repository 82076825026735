@import 'typeface-orbitron';

.dashboard_container {
  font-family: 'Orbitron', sans-serif;
}

.modal-content{
    display:flex;
    justify-content: center;
    align-items:center;
}
.dashboard_container{
    padding:10px;
}
.logo {
    position: absolute;
    top: 0;
    right: 0;
    padding:5px;
    transition: transform 0.3s ease;
    cursor:pointer;
    width: 50px; /* Reduce the width by 20% */
    height: auto; /* Maintain the aspect ratio */

  }
.logo:hover{
    transform: translateY(-5px);

}

.homeLogo{
    width:200px;
    height:auto;
}

